import styled from 'styled-components';
import Form from 'react-bootstrap/Form';

export const Field = styled(Form.Control)`
  &.form-control {
    height: 50px;
    background-color: #eee;
    border: none;

    &:focus {
      background-color: #eee;
    }

    &::placeholder {
      color: #9e9e9e;
    }
      
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #9e9e9e;
    }
      
    ::-ms-input-placeholder { /* Microsoft Edge */
      color: #9e9e9e;
    }
  }
`;

const SwitchLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 46px;
  height: 26px;

  & input { 
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 2px;
    bottom: 2px;
    background-color: var(--background-color);
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: var(--blue);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px var(--blue);
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 23px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
`;

export const Switch = ({ isOn, onClick }) => {
  return (
    <SwitchLabel className="switch">
      <input type="checkbox" checked={isOn} onChange={onClick} />
      <span className="slider round" />
    </SwitchLabel>
  );
};